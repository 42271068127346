@import '../../variables.scss';

.plate-grid {
    &__row {
        display: flex;
        justify-content: center;

        .plate-grid__item:nth-child(1) {
            text-transform: uppercase;
        }
    }

    &__item {
        width: 100%;
        height: 100%;
        max-width: 80px;
        min-height: 80px;

        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;
        
        padding: $spacer-xsmall;
        border-radius: $border-radius;
        margin: 1px;
        
        &--has-border {
            border: 1px solid $gray-500;
        }

        &--highlighted {
            background-color: $gray-background-hover;
        }
    }

    // .plate-grid__row:nth-child(2) {
    //     .plate-grid__item:nth-child(2) {
    //         border-radius: $border-radius 0 0 0;
    //     }
    //     .plate-grid__item:last-child {
    //         border-radius: 0 $border-radius 0 0;
    //     }
    // }

    // .plate-grid__row:last-child {
    //     .plate-grid__item:nth-child(2) {
    //         border-radius: 0 0 0 $border-radius;
    //     }
    //     .plate-grid__item:last-child {
    //         border-radius: 0 0 $border-radius 0;
    //     }
    // }
}