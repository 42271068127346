@import './variables.scss';

// Full bootstrap import
@import '~bootstrap/scss/bootstrap';

// Custom styles
// can use bootstrap variables and functions

body {
    margin: 0;
    padding: 0;
    color: $dark;
}

.btn--full {
    width: 100%;
}

// overwrite default button colors
.btn-primary,
.btn-outline-primary {
    --bs-btn-disabled-color: $btn-disabled-color;
    --bs-btn-disabled-border-color: $btn-disabled-color;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: .75;
}

.form-control:disabled,
.form-select:disabled {
    background-color: $gray-100;
}

.form-section {
    border-top: 4px solid $primary;
    padding: $spacer-medium $spacer-large;
}

.input-group-text {
    min-width: 50%;
    max-width: 50%;
    justify-content: flex-end;
    white-space: normal;
    text-align: right;
    background-color: tint-color($gray-200, 50%);
    align-items: flex-start;

    &.small {
        max-width: 30%;
        min-width: 30%;
    }

    &.unit {
        justify-content: center;
        align-items: center;
        font-size: small;
        min-width: 4rem;
        max-width: 4rem;
    }
}

.warning {
    background-color: tint-color($medium, 90%);
    padding: $spacer-medium;
}
