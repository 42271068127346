@import '../../variables.scss';

.upload-card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .c-danger,
    .c-success {
        display: inline-block;
        margin-right: $spacer-medium;
    }
}