@import '../../variables';

.table-entry {
    margin-bottom: $spacer-small;

    svg {
        color: $primary;
        width: 36px;
    }
   
    @include table-rows();

    &__risk {
        span {
            display: inline-block;
            min-width: 50px;
        }
    }

    &__actions {
        justify-content: space-around;
        display: flex;
    }
}
