@import '../../variables';

.modal-backdrop.show {
    backdrop-filter: blur(3px);
    opacity: 1 !important;
    background-color: #00000066;
}

.modal {
    .modal-title {
        display: flex;
        align-items: center;

        svg {
            width: 48px;
            margin-right: $spacer-medium;
            color: $primary;
        }
    }
    &--danger {
        .modal-title svg {
            color: $danger;
        }
    }

    &--warning {
        .modal-title svg {
            color: $warning;
        }
    }

    &--large {
        .modal-dialog {
            max-width: 1000px;
        }
    }

    &--small {
        .modal-dialog {
            max-width: 600px;
        }
    }
}
