@import '../../variables.scss';

.reagent-entry {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > * {
        margin: $spacer-small 0;
        width: 40%;

        &:nth-child(1) {
            margin-left: calc(20% - 3rem);
            width: 3rem;
            height: 3rem;
        }
    }

    & > * + * {
        margin-left: $spacer-large;
    }
}