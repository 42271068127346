@import '../../variables.scss';

.plate-item {
    position: relative;
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    font-size: 1.5rem;
    text-align: center;

    span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &--low {
        background-color: $low;
        color: $white;
    }
    &--medium {
        background-color: $medium;
        color: $white;
    }
    &--high {
        background-color: $high;
        color: $white;
    }
    &--blank {
        background-color: $gray-400;
    }
    &--calibrator {
        background-color: $gray-700;
        color: $white;
    }
    &--filled {
        background-color: $primary;
        color: $white;
        border-radius: 25%;

        font-size: 0.75rem;
    }

    &--empty {
        display: none;
    }
}
