@import '../../variables.scss';

.validation-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    &--error {
        .input-group-text, .checklist-wrapper {
            background-color: tint-color($error, 95%);
        }
        .validation-wrapper__message {
            color: $error;
        }
    }

    &__message {
        font-size: small;
        padding: $spacer-xsmall;
    }
}