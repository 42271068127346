@import '../../variables.scss';

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
        font-size: 1.5rem;
    }

    &__actions {
        * + * {
            margin-left: $spacer-large;
        }
    }
}
