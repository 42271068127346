@import '../../variables';

.checklist-wrapper {
    padding: $spacer-large;
    border-radius: $border-radius-input;
    background: tint-color($gray-200, 50%);
    border: 1px solid $gray-400;
    width: 100%;

    &__item {
        background-color: $white;
        display: flex;
        align-items: center;
        padding: $spacer-small $spacer-medium;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: $dark;
        border: 1px solid $gray-400;
        border-radius: $border-radius-input;
        height: 100%;

        .form-check {
            display: flex;
            align-items: center;

            input {
                margin-right: $spacer-medium;
                min-width: 1rem;
                margin-top: 0;
            }
        }
    }
}