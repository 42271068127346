@import '../../variables.scss';

.headline {
    background-color: $primary-background;
    padding: 1rem;
    margin: -1rem;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    span {
        display: flex;
        font-size: 12px;
        font-weight: 400;
    }
}
