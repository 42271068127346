@import '../variables';

.error-overlay {
    svg {
        color: $danger !important;
    }
    ul {
        max-height: 190px;
        border: 1px solid $divider-gray;
        border-radius: 4px;
        padding: $spacer-small;
        list-style-position: inside;
        overflow: auto;
    }
}