.text-center {
    text-align: center;
}

.block {
    display: block;
}

.as-link {
    border: none;
    background-color: transparent;
    color: $primary;
    cursor: pointer;

    &:hover {
        color: $primary-hover;
    }
}

.icon {
    width: 48px;
    height: 48px;

    &--success {
        color: $success;
    }
    &--danger {
        color: $danger;
    }

    &--white {
        color: $white;
    }

    &--small {
        width: 36px;
        height: 36px;
    }

    &--large {
        width: 64px;
        height: 64px;
    }
}

.icon-as-btn {
    width: 48px;
    height: 48px;
    color: $primary;
    cursor: pointer;

    &:hover {
        color: $primary-hover;
    }

    &--small {
        width: 36px;
        height: 36px;
    }
}

.c-danger {
    color: $danger !important;
}
.c-success {
    color: $success !important;
}

.flex {
    display: flex;
}
.inline-flex {
    display: inline-flex;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-end {
    justify-content: flex-end;
}

.align-start {
    align-items: flex-start;
}
.align-center {
    align-items: center;
}

.ml-1 {
    margin-left: $spacer-small;
}

.ml-2 {
    margin-left: $spacer-medium;
}

.ml-3 {
    margin-left: $spacer-large;
}

.text-gray {
    color: $gray-500;
}

.rotate-180 {
    transform: rotate(180deg);
}

@mixin notification($size) {
    position: absolute;
    top: -10px;
    right: -10px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    background-color: $info;
    color: tint-color($info, 90%);

    @if $size == small {
        width: 25px;
        height: 25px;
        font-size: 0.75rem;
    } @else {
        width: 40px;
        height: 40px;
    }
}

.divider {
    width: 100%;
    height: 1px;
    background-color: $divider-gray;
}