// set color defaults
$primary: #0066A4; // picked from logo
$secondary: #8A3D97; // picked from logo
$info: #e35d6a; // red-400
$btn-disabled-color: #787878;


/* Importing functions and Variables */
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

// Custom variables
$divider-gray: $gray-300;
$primary-hover: shade-color($primary, 15%);

$border-radius-small: 0.25rem;
$border-radius-medium: 0.5rem;
$border-radius-large: 0.75rem;

$border-radius-input: 0.375rem;

$spacer-xsmall: 0.25rem;
$spacer-small: 0.5rem;
$spacer-medium: 1rem;
$spacer-large: 2rem;

$primary-background: tint-color($primary, 95%);
$primary-background-hover: tint-color($primary, 90%);

$gray-background: tint-color($gray-500, 95%);
$gray-background-hover: tint-color($gray-500, 85%);

$low: tint-color($green, 40%);
$medium: tint-color($orange, 40%);
$high: tint-color($red, 40%);

$error: $danger;

@mixin hover-shadow() {
    box-shadow: 0px 0px 6px 3px $gray-300;
}

@mixin table-rows() {
    &:nth-child(1n) {
        background-color: $primary-background;

        &:hover {
            background-color: $primary-background-hover;
            @include hover-shadow;
        }
    }

    &:nth-child(2n) {
        background-color: $gray-background;

        &:hover {
            background-color: $gray-background-hover;
            @include hover-shadow;
        }
    }
}

// custom helper
@import './helper.scss';
