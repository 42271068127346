@import '../../variables.scss';

.scale {
    border-left: 1px solid $dark;
    border-right: 1px solid $dark;
    min-height: $spacer-large;
    width: 100%;
    position: relative;

    &__center {
        position: absolute;
        width: 1px;
        left: 50%;
        height: 60%;
        top: 20%;
        background-color: $dark;
    }

    &__line {
        position: absolute;
        width: 100%;
        height: 1px;
        top: 50%;
        background-color: $dark;
    }

    &__median {
        position: absolute;
        left: 50%;
        bottom: 80%;
        transform: translateX(-50%);
    }

    &__value {
        width: $spacer-medium;
        height: $spacer-medium;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &--primary {
        .scale__value {
            background-color: $primary;
        }
    }
    &--secondary {
        .scale__value {
            background-color: $secondary;
        }
    }
    &--info {
        .scale__value {
            background-color: $info;
        }
    }
}
