.risk-result-table {
    .generic-table__header > div,
    .generic-table__entries > div > div {
        max-width: unset;

        &:nth-child(1) {
            width: 40%;
        }
        &:nth-child(2) {
            width: 30%;
        }
        &:nth-child(3) {
            width: 30%;
        }
    }
}
