@import '../variables.scss';

.plate-map-page {
    .button-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > * {
            margin: 0 $spacer-small
        }

        svg {
            width: 48px;
            // color: mix($gray-500, $primary, 80%);
            color: $primary;
            cursor: pointer;

            &.legend {
                color: $primary;
            }
        }
    }
}