@import '../../variables.scss';

.risk-score {
    padding: $spacer-large;
    background-color: tint-color($gray-500, 95%);
    border-radius: $border-radius;

    &__indicator {
        position: relative;
        max-width: 1000px;
        margin: 0 auto;;
    }

    &__header,
    &__values,
    &__areas {
        display: flex;
        justify-content: space-between;
    }

    &__areas {
        margin: $spacer-small 0;
        div {
            height: 24px;
            width: 33%;
            margin: 0 $spacer-small;

            &:nth-child(1) {
                background-color: $low;
            }
            &:nth-child(2) {
                background-color: $medium;
            }
            &:nth-child(3) {
                background-color: $high;
            }
        }
    }

    &__values {
        span {
            display: inline-block;
            width: 32px;

            &:nth-child(3),
            &:nth-child(4) {
                text-align: right;
            }
        }
    }

    &__score {
        position: absolute;
        transform: translateX(-50%);
        width: 80px;
        text-align: center;
        font-weight: 700;
        font-size: 1.25rem;
        background-color: white;
        border-radius: $border-radius;

        &::before {
            content: '';
            display: block;
            width: 4px;
            height: 40px;
            position: absolute;
            left: 50%;
            bottom: 100%;
            background-color: $gray-500;
        }
    }

    &__risk {
        font-weight: 600;
        background-color: white;
        padding: $spacer-small;
        border-radius: $border-radius;
    }

    &--low {
        background-color: tint-color($low, 95%);
        .risk-score__score {
            color: $low;

            &::before {
                background-color: $low;
            }
        }
        .risk-score__risk {
            color: $low;
        }
    }
    &--medium {
        background-color: tint-color($medium, 95%);
        .risk-score__score {
            color: $medium;
            
            &::before {
                background-color: $medium;
            }
        }
        .risk-score__risk {
            color: $medium;
        }
    }
    &--high {
        background-color: tint-color($high, 95%);
        .risk-score__score {
            color: $high;

            &::before {
                background-color: $high;
            }
        }
        .risk-score__risk {
            color: $high;
        }
    }
}
