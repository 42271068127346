@import '../../variables.scss';

.consumable-entry {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > * {
        margin: $spacer-small 0;
        width: 40%;

        &:nth-child(1) {
            width: 20%;
        }
    }

    & > * + * {
        margin-left: $spacer-large;
    }
}