@import '../../variables';

.icon-button {
    position: relative;
    margin: 0 $spacer-large;
    max-width: 120px;
    text-align: center;
    cursor: pointer;

    * {
        transition: $transition-base;
    }

    &__notifications {
        @include notification(default);
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-large;
        width: 120px;
        height: 100px;
        background-color: $primary;

        svg {
            width: 100%;
            height: 100%;
            max-width: 96px;
            max-height: 76px;
            color: white;
        }
    }

    p {
        color: $primary;
    }

    &:hover {
        p {
            color: $primary-hover;
        }
        .icon-button__icon {
            background-color: $primary-hover;
        }
    }

    &--is-disabled {
        opacity: .5;

        cursor: not-allowed;

    }
}
