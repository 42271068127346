@import '../../variables';

.data-table {
    max-width: 1600px;
    margin: 0 auto;

    &__result {
        svg {
            width: 36px;
            height: 36px;
            color: $gray-400;
        }
        & > * + * {
            margin-left: $spacer-medium;
        }
    }

    

    &__pagination {
        display: flex;
        justify-content: center;
    }
}
