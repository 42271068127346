@import '../../variables.scss';

.plate-map-list {
    max-width: 800px !important;

    &__entry {
        border-radius: $border-radius-medium;
        background-color: $gray-background;
        padding: $spacer-medium;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__entry + &__entry {
        margin-top: $spacer-medium;
    }
}
