.yesnounknown-wrapper {
    align-items: flex-start !important;
    justify-content: space-between;

    .input-group {
        width: calc(100% - 190px);

        .input-group-text {
            width: 100%;
            max-width: 302px;
        }
    }
}