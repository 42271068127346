@import '../../variables.scss';

.generic-table {
    .generic-table__header,
    .generic-table__entries > div {
        display: flex;
        align-items: center;
        margin-bottom: $spacer-small;
        padding: $spacer-small;

        & > div {
            padding: $spacer-small;
            width: 100%;
            max-width: 300px;
        }
    }

    .generic-table__header {
        color: $gray-600;
    }

    .generic-table__entries > div {
        @include table-rows();
    }
}
