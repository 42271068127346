@import '../../../variables.scss';

.bulk-failed-samples {
    &__row {
        padding: 4px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $divider-gray;
        transition: all .3s ease-in-out;

        &:hover {
            background-color: $gray-background-hover;

            & > :nth-child(1) {
                transform: scale(1.5);
                transform-origin: left;
            }
        }

        &:nth-child(1) {
            font-weight: bold;
            font-size: 75%;
            
            &:hover {
                background-color: white;
            }
        }
        
        & > :nth-child(1) {
            flex-basis: 10%;
            margin-right: 5px;
            font-weight: bold;
        }
        & > :nth-child(2) {
            flex-basis: 50%;
            margin-right: 5px;
        }
        & > :nth-child(3) {
            flex-basis: 25%;
        }
    }

    &__metabolites {
        span {
            display: block;
            font-size: 80%;
            margin: 2px 0;
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__action {
        svg {
            width: 24px;
            height: 24px;
        }

        &--approved,
        &--rejected {
            font-size: 80%;
        }

        &--approved {
            color: $success;
        }

        &--rejected {
            color: $danger;
        }
    }

    &__toggle {
        @extend .as-link;
        display: inline-flex;
        align-items: center;

        svg {
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
    }

    &__range {
        display:  inline-block;
        width: 50px;
        &--high {
            color: $danger;
        }
        &--low {
            color: $medium;
        }
    }
}
