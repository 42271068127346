@import '../../variables.scss';

.record-details {
    ul.record-details__main {
        font-size: 1.5rem;
        padding: 1rem;
        background-color: $primary-background;
        border-radius: $border-radius-medium;

        & > li > span {
            &:nth-child(1) {
                min-width: 120px;
                font-size: 1rem;
            }
        }
    }

    h5 {
        padding: 4px 8px;
        border-radius: $border-radius-small;
        background-color: $primary-background;
    }
    
    ul,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        padding: 0 8px;
    }

    ul > li {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 2px 8px;
        border-radius: $border-radius-small;

        &:hover {
            background-color: #efefef;
        }

        & + li {
            border-top: 1px solid $divider-gray;
        }
    }

    ul > li > span {
        &:nth-child(1) {
            font-weight: 500;
            min-width: 240px;
            margin-right: 5%;
        }
    }

    ul + ul {
        margin-top: $spacer-large;
    }

    .indented {
        margin-left: $spacer-medium;
        & > span:nth-child(1) {
            min-width: calc(240px - $spacer-medium);
        }
    }

    .more-indented {
        margin-left: $spacer-large;

        & > span:nth-child(1) {
            min-width: calc(240px - $spacer-large);
        }
    }
}
