.sample-table {
    .generic-table__header > div, 
    .generic-table__entries > div > div {
        &:nth-child(1) {
            width: 10%;
        }
        &:nth-child(2),
        &:nth-child(3) {
            width: 12%;
        }
        &:nth-child(4) {
            width: 10%;
        }
        &:nth-child(5),
        &:nth-child(6) {
            width: 24%;
        }
        &:nth-child(7) {
            width: 10%;
        }
    }

    .generic-table__entries > div > div:nth-child(5) > span + span {
        margin-left: 10px;
    }

    .generic-table__entries > div > div:nth-child(7) {
        display: flex;
        justify-content: space-between;
    }

    svg {
        width: 36px;
    }
}