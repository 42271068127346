.sample-candidates-table {
    .generic-table__header > div, 
    .generic-table__entries > div > div {
        &:nth-child(1) {
            width: 10%;
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            width: 30%;
        }
        
    }
}